const drawPoints = (context, points) => {
  // move to the first point
  context.moveTo(points[0].x, points[0].y);

  let i;
  for (i = 1; i < points.length - 2; i++) {
  var xc = (points[i].x + points[i + 1].x) / 2;
  var yc = (points[i].y + points[i + 1].y) / 2;
    context.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
  }
  // curve through the last two points
  context.quadraticCurveTo(points[i].x, points[i].y, points[i+1].x,points[i+1].y);
};

export default class Waves {
  constructor ({ width, iterations, padding, offset, canvas }) {
    this.iterations = iterations;
    this.width = width;
    this.padding = padding;
    this.grid = [new Array(width).fill(1)];
    this.jump = new Array(width).fill(1);
    this.mutation = new Array(width).fill(0);
    this.offset = offset;
    this.canvas = canvas;
  }

  addLine (idx) {
    let average = this.rowAverage(idx);
    this.jump = this.jump.map((o, x) => {
      let diff = this.grid[this.grid.length - 1][x] - average;
      o *= ((Math.random() - 0.5)) / 10 + 1 + (-diff / 40);
      return o;
    });

    let row = [];
    for (let x = 0; x < this.width; x++) {
      row[x] = Math.round((this.jump[x] + this.grid[this.grid.length - 1][x]) * 10000) / 10000;
    }

    this.grid.push(row);
  }

  rowAverage (level) {
    return this.grid[level].reduce((a, b) => a + b, 0) / this.width;
  }

  draw (mutate_step) {
    var canvas = this.canvas || document.querySelector("canvas");
    var context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    if (!mutate_step) mutate_step = 0;

    let end_average = this.rowAverage(this.grid.length - 1);

    let p1 = 3;

    this.grid.slice(this.iterations - 200).forEach((line, line_idx) => {
      context.beginPath();
      drawPoints(context, line.map((o, x) => {
        return {
          x: this.padding + (x * (canvas.width / (this.width - 1)) * (canvas.width - this.padding * 2) / canvas.width),
          y: (o - end_average + (this.offset || 80)) * 30 + line_idx * -4 + (mutate_step * this.mutation[x]),
        };
      }));

      if (line_idx === this.grid.length - (this.iterations - 200) - p1 + 2) {
        context.strokeStyle = "#ff3887";
        context.lineWidth = 4;
      } else if (line_idx === this.grid.length - (this.iterations - 200) - p1) {
        context.strokeStyle = "#ffea6f";
        context.lineWidth = 4;
      } else if (line_idx === this.grid.length - (this.iterations - 200) - p1 + 1) {
        context.strokeStyle = "#ff6262";
        context.lineWidth = 4;
      } else {
        context.strokeStyle = "#fff";
        context.lineWidth = 2;
      }
      context.stroke();
    });

    context.clearRect(0, 0, canvas.width, this.padding)
  }

  cached (values) {
    this.grid = values;
    return this;
  }

  run () {
    let d = new Date();
    for (let x = 0; x < this.iterations; x++) {
      this.addLine(x);
    }

    this.draw();

    return this;
  }

  mutate () {
    for (let x = 0; x < this.width; x++) {
      this.mutation[x] += Math.random() * 10 - 5;
      if (this.mutation[x] > 10) this.mutation[x] *= 0.5;
      if (this.mutation[x] < -10) this.mutation[x] *= 0.5;
    }

    this.draw();
  }
}
