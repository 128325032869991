import React from 'react';
import checkmark from "../../assets/checkmark.png";

import "../../css/widget.css";

import Calculate from "../modules/Calculate.js";
import { formData } from "../modules/FormData.js";

import TableRowsReadOnly from "./TableRowsReadOnly.js";

import Icons from "../modules/Icons";
const { StarIcon, EmptyStarIcon } = Icons;
import Select from "../modules/Select.js";

class KeyValueWidget extends React.Component {
  constructor () {
    super();
  }

  render () {
    const dims = `${this.props.dim.width}-${this.props.dim.height}`;
    console.log(this.props)
    return (
      <div className={"widget--wrapper size--" + dims}>
        <div className="header">
          <label>{this.props.title}</label>
          <button className="button button--small-label button--red" onClick={idx => this.props.delete(idx)}>Delete</button>
        </div>
        <div className={"widget flex-column widget--key-value"}>
          <div className="value">{ this.props.data.value }</div>
          <div className="key">{ this.props.label || this.props.data.label }</div>
        </div>
      </div>
    );
  }
}

class KeyValueGroupWidget extends React.Component {
  constructor () {
    super();
  }

  render () {
    let { options, dim } = this.props;
    if (!options) options = {};
    const dims = `${dim.width}-${dim.height}`;

    return (
      <div className={"widget--wrapper size--" + dims}>
        <div className="header">
          <label>{this.props.title}</label>
          <button className="button button--small-label button--red" onClick={idx => this.props.delete(idx)}>Delete</button>
        </div>
        <div className={"widget widget--key-value widget--equal-space"}>
          { this.props.data.map(data => (
            <div className="widget--cell">
              <div className="value">{ data.value }</div>
              <div className="key" style={{ opacity: options.labelOpacity }}>{ data.label }</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class TableSnippet extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <div className="widget--wrapper widget--snippet">
        <div className="header">
          <label>{this.props.title}<span className="preview-only">Preview Only</span></label>
          <button className="button button--small-label button--red" onClick={idx => this.props.delete(idx)}>Delete</button>
        </div>
        <TableRowsReadOnly
          setInformationSidebar={this.props.setInformationSidebar}
          style={{ transform: `scale(0.5)` }}
          spreadsheet={this.props.spreadsheet}
          columns={this.props.columns}
        />
      </div>
    )
  }
}

const StringToCalculate = (spreadsheet, columns, procedures) => {
  let c = Calculate({ type: "spreadsheet", data: spreadsheet }, columns);

  for (let x = 0; x < procedures.length; x++) {
    if (!c[procedures[x][0]]) {
      console.warn(`The function "${procedures[x][0]}" could not be done in the sequence below.`);
      console.warn(procedures);
    }
    c = c[procedures[x][0]](procedures[x][1]);
  }

  return c;
};

class AddWidget extends React.Component {
  constructor () {
    super();

    this.state = {
      type: null,
    }
  }

  onChange (type) {
    this.setState({ type });
    document.activeElement.blur();
    const $el = document.querySelector(".add-widget > .select-configure");
    // do this becuase otherwise it fades out on top of the modal which I don't really want.
    // TODO: Some repositioning from the bottom may work for this.
    $el.style.display = "none";
    setTimeout(() => {
      $el.style.display = "";
    }, 300);
  }

  selectToInput (ref_name, value) {
    if (this[ref_name]) {
      this[ref_name].value = value;
    }

    document.activeElement.blur();
  }

  createWidget () {
    let data = formData(this.form);

    if (this.state.type === "Single Value") {
      this.props.addWidget({
        type: "key-value",
        dim: [+data.width, +data.height],
        title: data.title,
        label: data.label.length > 0 ? data.label : undefined,
        data: [
          ["Column", data.column],
          ["toCell", data.function.replace(/\s/g, "")],
        ],
      });

      this.props.closeWidget();
    } else if (this.state.type === "Group By") {
      this.props.addWidget({
        type: "group-by-key-value",
        dim: [+data.width, +data.height],
        title: data.title,
        label: (data.label && data.label.length > 0) ? data.label : undefined,
        data: [
          ["GroupBy", data.group_by],
          ["Column", data.column],
          ["toCell", data.function.replace(/\s/g, "")],
        ],
        format: data.format ? data.format : undefined,
      });

      this.props.closeWidget();
    } else if (this.state.type === "Table Snippet") {
      console.log(data);

      const sort_col_idx = this.props.columns.findIndex(o => data.sort_column === o.label);
      const sign = data.sort_order === "Ascending" ? ">" : "<";

      this.props.addWidget({
        type: "table-snippet",
        title: data.title,
        sort: `a[${sort_col_idx}].value ${sign} b[${sort_col_idx}].value ? 1 : -1`,
        filter: data.filter,
        limit: data.limit,
      });

      this.props.closeWidget();
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.show === false && prevProps.show === true) setTimeout(() => this.setState({ type: null }), 300);
  }

  render () {
    const func_names = Object.keys(Calculate.Maps.array.cell);

    return (
      <div className={"add-widget " + (this.props.show ? "show" : "")} ref={node => this.form = node}>
        <h3 className="pseudo-focus" data-placeholder="Widget Type +" tabIndex={1}>{ this.state.type }</h3>
        <Select ref={node => this.type_select = node} arrowPosition={"bottom"} onChange={option => this.onChange(option, this.type_select)} options={["Single Value", "Group By", "Table Snippet"]} />
        { this.state.type && (
          <>
            <div className="input-box">
              <label>Title</label>
              <input name="title" type="text" placeholder="Number of Users" />
            </div>
            { ["Single Value"].indexOf(this.state.type) !== -1 && <div className="input-box">
              <label>Label</label>
              <input name="label" type="text" placeholder="Users" />
            </div> }
            { ["Single Value", "Group By"].indexOf(this.state.type) !== -1 && <div>
              <label className="label--main">Dimensions</label>
              <div className="input-row">
                <div className="input-box half">
                  <label>Width</label>
                  <input name="width" type="number" defaultValue={1} max="5" />
                </div>
                <div className="input-box half">
                  <label>Height</label>
                  <input name="height" type="number" defaultValue={1} max="1" />
                </div>
              </div>
            </div> }
          </>
          ) }
        { (() => {
            if (this.state.type === "Single Value") {
              return (
                <>
                  <div className="input-row">
                    <div className="input-box half">
                      <label>Function</label>
                      <input name="function" ref={node => this.single_value_func = node} type="text" className="pseudo-focus" placeholder={"count"} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("single_value_func", option)} options={func_names.map(o => o.replace(/([A-Z])/g, " $1").trim())} />
                    </div>
                    <div className="input-box half">
                      <label>Column</label>
                      <input name="column" ref={node => this.single_value_column = node} type="text" className="pseudo-focus" placeholder={this.props.columns[0].label} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("single_value_column", option)} options={this.props.columns.map(o => o.label)} />
                    </div>
                  </div>
                </>
              );
            } else if (this.state.type === "Group By") {
              return (
                <>
                  <div className="input-row">
                    <div className="input-box">
                      <label>Group By</label>
                      <input name="group_by" ref={node => this.group_by_group_by_column = node} type="text" className="pseudo-focus" placeholder={this.props.columns[0].label} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("group_by_group_by_column", option)} options={this.props.columns.map(o => o.label)} />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-box half">
                      <label>Function</label>
                      <input name="function" ref={node => this.group_by_func = node} type="text" className="pseudo-focus" placeholder={"count"} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("group_by_func", option)} options={func_names.map(o => o.replace(/([A-Z])/g, " $1").trim())} />
                    </div>
                    <div className="input-box half">
                      <label>Column</label>
                      <input name="column" ref={node => this.group_by_column = node} type="text" className="pseudo-focus" placeholder={this.props.columns[1].label} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("group_by_column", option)} options={this.props.columns.map(o => o.label)} />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-box half">
                      <label>Format</label>
                      <input name="format" ref={node => this.group_by_format = node} type="text" className="pseudo-focus" placeholder={"Choose..."} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("group_by_format", option)} options={["stars"]} />
                    </div>
                  </div>
                </>
              );
            } else if (this.state.type === "Table Snippet") {
              return (
                <>
                  <div className="input-row">
                    <div className="input-box">
                      <label>Filter</label>
                      <input className="mono" name="filter" type="text" tabIndex="1" placeholder={"!o[0].value && o[4].value === 'Bug'"} />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-box half">
                      <label>Sort</label>
                      <input name="sort_column" ref={node => this.table_snippet_sort_column = node} type="text" className="pseudo-focus" placeholder={this.props.columns[0].label} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("table_snippet_sort_column", option)} options={this.props.columns.map(o => o.label)} />
                    </div>
                    <div className="input-box half">
                      <label>Order</label>
                      <input name="sort_order" ref={node => this.table_snippet_sort_order = node} type="text" className="pseudo-focus" placeholder={"Descending"} tabIndex="1" />
                      <Select arrowPosition={"bottom"} onChange={option => this.selectToInput("table_snippet_sort_order", option)} options={["Ascending", "Descending"]} />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-box half">
                      <label>Limit</label>
                      <input name="limit" type="number" placeholder={3} tabIndex="1" />
                    </div>
                  </div>
                </>
              );
            }
          })() }
          { this.state.type && <button className="button button--blue" tabIndex="1" onClick={() => this.createWidget()}>Save</button> }
      </div>
    );
  }
}

export default class SpreadsheetDashboard extends React.Component {
  constructor () {
    super();

    this.state = {
      animate_out: false,
      widgets: [/*
        {
          type: "key-value",
          dim: [1, 1],
          title: "Total Issues",
          data: [
            ["Column", "Done"],
            ["toCell", "count"],
          ],
        }, {
          type: "key-value",
          dim: [2, 1],
          title: "Issues Completed",
          label: "Complete",
          data: [
            ["Column", "Done"],
            ["toCell", "ProportionTrue"],
          ],
        }, {
          type: "group-by-key-value",
          dim: [3, 1],
          title: "Number of Issues by Difficulty",
          data: [
            ["GroupBy", "Importance"],
            ["Column", "Done"],
            ["toCell", "count"],
          ],
          format: "stars",
        },
        {
          type: "table-snippet",
          title: "Top Unfinished Issues",
          sort: "a[3].value < b[3].value ? 1 : -1",
          filter: "!o[0].value",
          limit: 3,
        }, {
          type: "table-snippet",
          title: "Top Unfinished Bugs",
          sort: "a[3].value < b[3].value ? 1 : -1",
          filter: "!o[0].value && o[4].value === 'Bug'",
          limit: 3,
        },
      */],
    }
  }

  componentDidMount () {
    if (this.props.show === true) {
      this.dashboard.classList.add("show");
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.show && this.props.show) {
      this.dashboard.classList.add("show");
    } else if (!this.props.show && prevProps.show) {
      this.setState({ animate_out: true });
      console.log("remove")
      this.dashboard.classList.remove("show");
      setTimeout(() => {
        this.setState({ animate_out: false })
      }, 1000);
    }
  }

  toggleAddWidget () {
    this.setState({ add_widget: !this.state.add_widget });
  }

  addWidget (widget) {
    this.setState({
      widgets: this.state.widgets.concat(widget),
    });
  }

  delete (idx) {
    this.setState({ widgets: this.state.widgets.slice(0, idx).concat(this.state.widgets.slice(idx + 1)) });
  }

  render () {
    const { spreadsheet, columns } = this.props;

    const widgets = this.state.widgets;

    return (
      <div ref={node => this.dashboard = node ? node : this.dashboard} className="spreadsheet-dashboard">
        { (this.props.show || this.state.animate_out) &&
          <>
          { widgets.map((widget, idx) => {
              if (widget.type === "key-value") {
                let data = StringToCalculate(spreadsheet, columns, widget.data);
                console.log(data, widget);
                return <KeyValueWidget dim={{ width: widget.dim[0], height: widget.dim[1] }} title={widget.title} data={data.data} label={widget.label} delete={() => this.delete(idx)} />
              } else if (widget.type === "group-by-key-value") {
                let data = StringToCalculate(spreadsheet, columns, widget.data);
                let options = {};
                if (widget.format === "stars") {
                  console.log("stars", data);
                  data.data.forEach(o => o.label = new Array(+o.label).fill(<StarIcon className="star" />));
                  options.labelOpacity = 1;
                }
                return <KeyValueGroupWidget options={options} title={widget.title} dim={{ width: widget.dim[0], height: widget.dim[1] }} data={data.data} delete={() => this.delete(idx)} />
              } else if (widget.type === "table-snippet") {
                let spreadsheet = [...this.props.spreadsheet];
                if (widget.sort) spreadsheet.sort((a, b) => eval(widget.sort));
                if (widget.filter) spreadsheet = spreadsheet.filter(o => eval(widget.filter));
                if (widget.limit) spreadsheet = spreadsheet.slice(0, widget.limit);

                return <TableSnippet
                  setInformationSidebar={this.props.setInformationSidebar}
                  title={widget.title}
                  spreadsheet={spreadsheet}
                  columns={this.props.columns}
                  delete={() => this.delete(idx)}
                />
              }
            }) }
          </>
        }
        <button className={"button button--add-button " + (this.state.add_widget ? "rotate" : "")} onClick={() => this.toggleAddWidget()}><span>+</span></button>
        <AddWidget closeWidget={() => this.toggleAddWidget()} show={this.state.add_widget} addWidget={widget => this.addWidget(widget)} columns={this.props.columns} />
      </div>
    )
  }
}
