import React from 'react';

import Text from "./cells/Text.js";
import LongText from "./cells/LongText.js";
import PhoneNumber from "./cells/PhoneNumber.js";
import LatLong from "./cells/LatLong.js";
import Checkbox from "./cells/Checkbox.js";
import NumberCell from "./cells/Number.js";
import Computed from "./cells/Computed.js";
import Tags from "./cells/Tags.js";
import SelectCell from "./cells/Select.js";
import Rating from "./cells/Rating.js";
import Date from "./cells/Date.js";

import preview from "../../assets/preview.png";

export default class InformationSidebarTable extends React.Component {
  constructor () {
    super();

  }

  componentDidMount () {
    console.log("mount --")
  }

  componentDidUpdate () {
    console.log("update --")
  }

  render () {
    const ranges = this.props.columns.map((o, col_idx) => {
      if (o.type !== "Number") return;

      let values = this.props.spreadsheet.map(row => parseFloat(row[col_idx].value)).filter(o => !isNaN(o));

      let min = Math.min.apply(Math, values);
      let max = Math.max.apply(Math, values);

      return { min, max };
    });

    const { row_idx } = this.props;
    return (
      <>
        <h1>{ this.props.spreadsheetName }</h1>
        <p>(Row { this.props.row_idx + 1 })</p>
        <div className="row" key={row_idx}>
        { this.props.spreadsheet[row_idx].map((col, idx) => (
          <>
            <div
              className={"cell " + "cell-" + idx}
              onMouseDown={e => this.props.onCellMouseDown(e, idx)}
              onMouseMove={e => this.props.onCellMouseMove(e, idx)}
              onMouseUp={e => this.props.onCellMouseUp()}
              key={idx}
              data-type={this.props.columns[idx].type}
              onDoubleClick={e => e.target.parentNode.classList.add("expand")}
            >
              <label>{ this.props.columns[idx].label }</label>
            {(() => {
              const { type, config } = this.props.columns[idx];

              if (type === "Text") {
                return (
                  <Text
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Phone Number") {
                return (
                  <PhoneNumber
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    config={config || {}}
                  />
                )
              } else if (type === "Lat/Long") {
                return (
                  <LatLong
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Long Text") {
                return (
                  <LongText
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Checkbox") {
                return (
                  <Checkbox
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Number") {
                return (
                  <NumberCell
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    rangeValues={ranges[idx]}
                    config={config || {}}
                  />
                )
              } else if (type === "Computed") {
                return (
                  <Computed
                    row={this.props.spreadsheet[row_idx].map(cell => cell.value)}
                    columns={this.props.columns}
                    value={col.values}
                    config={config || {}}
                  />
                )
              } else if (type === "Tags") {
                return (
                  <Tags
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                    addTag={tags => this.props.saveColumnAttribute(idx, { tags })}
                  />
                )
              } else if (type === "Select") {
                return (
                  <SelectCell
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                    addSelectOption={selectOptions => this.props.saveColumnAttribute(idx, { selectOptions })}
                  />
                )
              } else if (type === "Rating") {
                return (
                  <Rating
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                  />
                )
              } else if (type === "Date") {
                return (
                  <Date
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    config={config || {}}
                  />
                )
              }
            })()}
            </div>
          </>
          )) }
          <button
            type="button"
            className="delete-row warning"
            onClick={() => this.props.deleteRow(row_idx)}
          >Delete Row</button>
        </div>
      </>
    );
  }
}
