const IF = (exp, success, fail) => {
  return exp ? success : fail;
};

const PILL = (str) => {
  return `<span class="cell--pill">${str}</span>`
}

const LABEL = (str) => {
  return `<span class="cell--label">${str}</span>`
}

export default function Compute (valuesObject, evaluatedPhrase) {
  let obj = {};
  const AS = (key, value) => {
    if (typeof value === "undefined") {
      console.log(obj)
      return obj[key];
    } else {
      obj[key] = value;
      return value;
    }
  };

  let col = valuesObject;
  try {
    return eval(evaluatedPhrase);
  } catch (e) {
    return "";
  }
}
