import React from 'react';

import TableRows from "./TableRows.js";

export default class TableRowsReadOnly extends React.Component {
  constructor () {
    super();

  }

  render (props) {
    return (
      <div className="table--read-only">
        <TableRows
          columns={this.props.columns}
          setCellValue={() => {}}
          getCellValue={() => {}}
          onCellMouseDown={() => {}}
          onCellMouseMove={() => {}}
          onCellMouseUp={() => {}}
          spreadsheet={this.props.spreadsheet}
          addRow={() => {}}
          deleteRow={() => {}}
          setInformationSidebar={this.props.setInformationSidebar}
          saveColumnAttribute={() => {}}
          readOnly={true}
        />
      </div>
    );
  }
}
