import React from 'react';

import FormattedText from "../../modules/FormattedText.js";
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

export default class Date extends Cell {
  constructor () {
    super();

    this.state = {

    }
  }

  componentDidMount () {
    // if this already has a value attribute that means we have already
    // initialized an instance of this. It should *probably* only ever
    // happen once.
    new FormattedText(this.cell, `dd/dd/dddd`).style({
      template: {
        margin: "0px -1px 0px -2px",
      }
    }).placeholder(["MM", "DD", "YYYY"]).render();

    this.cell.value = this.props.getCellValue();
  }

  save () {
    this.props.save(this.cell.value);
  }

  render () {
    return (
      <span
        style={ computeStyleFromConfig(this.props.config) }
        ref={cell => this.cell = cell}
        onBlur={() => this.save()}
      ></span>
    );
  }
}
