import React from 'react';

import FormattedText from "../../modules/FormattedText.js";
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';

export default class Cell extends React.Component {
  constructor () {
    super();
  }

  shouldComponentUpdate (nextProps) {
    // check if any of these primary values have been updated.
    if (
      (this.props.columns === nextProps.columns) &&
      (!nextProps.rangeValues || (this.props.rangeValues.min === nextProps.rangeValues.min && this.props.rangeValues.max === nextProps.rangeValues.max)) &&
      (this.props.value === nextProps.value) &&
      (JSON.stringify(this.props.config) === JSON.stringify(nextProps.config))
    ) return false;

    return true;
  }
}
