const TextEdit = (() => {
  function createRange(node, chars, range) {
      if (!range) {
          range = document.createRange()
          range.selectNode(node);
          range.setStart(node, 0);
      }

      if (chars.count === 0) {
          range.setEnd(node, chars.count);
      } else if (node && chars.count >0) {
          if (node.nodeType === Node.TEXT_NODE) {
              if (node.textContent.length < chars.count) {
                  chars.count -= node.textContent.length;
              } else {
                  range.setEnd(node, chars.count);
                  chars.count = 0;
              }
          } else {
             for (var lp = 0; lp < node.childNodes.length; lp++) {
                  range = createRange(node.childNodes[lp], chars, range);

                  if (chars.count === 0) {
                      break;
                  }
              }
          }
      }

      return range;
  };

  function setCurrentCursorPosition(el, chars) {
      if (chars >= 0) {
          var selection = window.getSelection();

          var range = createRange(el, { count: chars });

          if (range) {
              range.collapse(false);
              selection.removeAllRanges();
              selection.addRange(range);
          }
      }
  };

  function isChildOf(node, parent) {
      while (node !== null) {
          if (node === parent) {
              return true;
          }
          node = node.parentNode;
      }

      return false;
  };

  function getCurrentCursorPosition(elem) {
      var selection = window.getSelection(),
          charCount = -1,
          node;

      if (selection.focusNode) {
          if (isChildOf(selection.focusNode, elem)) {
              node = selection.focusNode;
              charCount = selection.focusOffset;

              while (node) {
                  if (node === elem) {
                      break;
                  }

                  if (node.previousSibling) {
                      node = node.previousSibling;
                      charCount += node.textContent.length;
                  } else {
                       node = node.parentNode;
                       if (node === null) {
                           break
                       }
                  }
             }
        }
     }

      return charCount;
  };

  const template = `ddd-ddd-dddd`;

  const textToStringTemplate = (text, template) => {
    let str = ``,
        pointer = 0;

    for (let x = 0; x < template.length; x++) {
      if (template[x] === "d") {
        str += (text[pointer++] || "");
      } else {
        str += template[x];
      }

      if (pointer === text.length) break;
    }

    console.log({ str })

    return str;
  };

  const stringTemplateToText = (text, template) => {
    let str = ``;

    for (let x = 0; x < template.length; x++) {
      if (template[x] === "d" && /[0-9]/.test(text[x])) str += (text[x] || "");
    }

    return str;
  };

  const transformCaretPosition = (text, template, pos) => {
    let caret = 0;
    for (let x = 0; x < pos; x++) {
      if (template[x] === "d") caret++;
    }

    return caret;
  };

  const transformCaretPositionToTemplate = (text, template, pos) => {
    let caret = 0, idx = 0;
    console.log(text, template, pos)
    for (let x = 0; x < template.length; x++) {
      if (template[x] === "d") caret++;
      if (caret === pos + 1) return x;
    }
  };

  return {
    getCurrentCursorPosition,
    setCurrentCursorPosition,
    textToStringTemplate,
    transformCaretPosition,
    stringTemplateToText,
    transformCaretPositionToTemplate,
  };
})();

export default TextEdit;
