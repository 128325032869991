import React from 'react';
import checkmark from "../../assets/checkmark.png";

export default class InformationSidebar extends React.Component {
  constructor () {
    super();

    this.state = {

    }
  }

  componentDidMount () {

  }

  componentDidUpdate () {

  }

  close (e) {
    const { target } = e;

    if (!(target.closest(".information-sidebar") || target.matches(".information-sidebar"))) {
      this.overlay.classList.remove("show");
      setTimeout(() => this.props.close(target), 300);
    }
  }

  render () {
    return (
      <div
        ref={node => this.overlay = node}
        className={"overlay " + (this.props.show ? "show" : "")}
        onClick={e => this.close(e)}>
        <div className="information-sidebar">{ this.props.show && this.props.children }</div>
      </div>
    );
  }
}
