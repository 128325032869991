import React from 'react';
import ReactDOM from 'react-dom';

import LoadTable from './LoadTable.js'
import StyleSheet from '../modules/StyleSheet.js';
import TableRows from "./TableRows.js";
import InformationSidebarTable from "./InformationSidebarTable.js";
import InformationSidebar from "../components/InformationSidebar.js";
import TableHeaderSelect from '../modules/TableHeaderSelect.js';

import SpreadsheetDashboard  from "./SpreadsheetDashboard.js";

import icons from '../modules/Icons.js';

const findRule = (sheet, stylesheet, idx) => {
  let { cssRules } = stylesheet.stylesheet.sheet;

  for (let x = 0; x < cssRules.length; x++) {
    if (cssRules[x].selectorText === `.sheet-${sheet} .cell-${idx}`) {
      return cssRules[x];
    }
  }
};

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  }
};

const save = {
  columns: (() => {
    return debounce((spreadsheet_key, columns) => fetch(`/api/spreadsheets/${spreadsheet_key}/columns/`, {
      method: 'POST',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
      body: JSON.stringify({
        data: JSON.stringify(columns),
      }), // body data type must match "Content-Type" header
    }), 1000);
  })(),

  removeRow: (spreadsheet_key, row_idx) => {
    fetch(`/api/spreadsheets/${spreadsheet_key}/rows/${row_idx}/`, {
      method: 'DELETE',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
    })
  },

  row: (() => {
    return debounce((spreadsheet_key, row, row_idx) => {
      fetch(`/api/spreadsheets/${spreadsheet_key}/rows/${row_idx}/`, {
        method: 'POST',             // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',         // manual, *follow, error
        referrer: 'no-referrer',    // no-referrer, *client
        body: JSON.stringify({
          data: row.map(o => o.value),
        }), // body data type must match "Content-Type" header
      })
    }, 500);
  })(),

  sheetName: (() => {
    return debounce((spreadsheet_key, name) => {
      fetch(`/api/spreadsheets/${spreadsheet_key}/`, {
        method: 'PUT',             // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',         // manual, *follow, error
        referrer: 'no-referrer',    // no-referrer, *client
        body: JSON.stringify({
          name: name,
        }), // body data type must match "Content-Type" header
      })
    }, 500);
  })(),
}

const closest = (node, sel) => {
  while (node.matches && !node.matches(sel)) {
    node = node.parentNode;
  }

  return (typeof node.matches === "function" && node.matches(sel));
};

const formatCell = {
  phoneNumber: (value) => {
    let mod = value.replace(/[^0-9]/g, "");

    if (mod.length === 7) {
      mod = mod.replace(/(.{3})(.{4})/, "$1-$2");
      return mod;
    }

    if (mod.length === 10) {
      mod = mod.replace(/(.{3})(.{3})(.{4})/, "($1)-$2-$3");
      return mod;
    }

    return value;
  }
};

export default class Spreadsheet extends React.Component {
  constructor (props) {
    super(props);

    const { oldState } = this.props;

    this.state = {
      spreadsheet: oldState.spreadsheet,
      columns: oldState.columns || new Array(5).fill(0).map(o => ({
        label: ``,
        type: "Text",
      })),

      mouseDown: false,
      lastSaved: false,
      name: this.props.name,

      label: this.props.label,
      id: this.props.id,
      informationSidebarRowIdx: null,
      show_information_sidebar: false,
      show_table: true,
      doRender: this.props.active,
    };

    this.justAddedRow = false;
    this.justDeletedRow = false;
    this.justAddedColumn = false;

    this.lastXY = null;
    this.cellIndex = null;
    this.mouseDown = null;
    this.cells = [];
    this.table = null;
    this.Portal = null;

    this.file_upload = document.createElement("input");
    this.file_upload.type = "file";

    this.stylesheet = new StyleSheet(`.sheet-${this.state.id}`, this.state.columns.map(o => o.width));
  }

  onCellMouseDown (e, idx) {
    this.mouseDown = true;

    let coords = e.target.getBoundingClientRect();
    if (coords.width + coords.x - e.pageX < 10) {
      this.cellIndex = idx;
      this.cellModifyPosition = "right";
    } else if (e.pageX - coords.x < 10) {
      this.cellIndex = idx - 1;
      this.cellModifyPosition = "left";
    } else {
      this.cellModifyPosition = null;
    }
  }

  onCellMouseUp () {
    this.mouseDown = false;
    this.lastXY = null;

    let rule = findRule(this.state.id, this.stylesheet, this.cellIndex);
    if (isNaN(this.cellIndex) || this.cellIndex === null) return true;

    rule.style.boxShadow = ``;
  }

  onCellMouseMove (e, idx) {
    if (this.mouseDown && this.cellModifyPosition) {
      let coords = e.target.getBoundingClientRect();
      let rule = findRule(this.state.id, this.stylesheet, this.cellIndex);

      if (!this.lastXY) {
        this.lastXY = e.pageX;
      } else {
        let diff = this.lastXY - e.pageX;
        if ((parseInt(rule.style.width) - diff) < 80) return;
        rule.style.width = (parseInt(rule.style.width) - (diff * 2)) + "px";
        this.setState({
          columns: this.state.columns.map((o, i) => {
            if (i === idx) return Object.assign({}, o, { width: (parseInt(rule.style.width) - (diff * 2)) });
            return o;
          }),
        }, () => {
          save.columns(this.state.id, this.state.columns);
          this.setState({ lastSaved: new Date() });
          console.log("save columns")
        });
        rule.style.boxShadow = `inset -4px 0px 0px -2px var(--cell-border)`;

        this.lastXY = e.pageX;
      }
    }
  }

  setSheetName (e) {
    const { target } = e;
    this.setState({
      name: target.innerText,
    }, () => {
      save.sheetName(this.state.id, target.innerText);
      this.setState({ lastSaved: new Date() });
    });
  }

  addRow () {
    this.setState({
      spreadsheet: this.state.spreadsheet.concat([this.state.columns.map(o => ({
        value: "",
      }))]),
    });

    this.justAddedRow = true;
  }

  addRowsWithData (row) {
    let { columns } = this.state;
    if (rows.reduce((acc, row) => acc || (columns.length !== row.length), false)) {
      console.warn(`Error: the inputted source doesn't have the same number of columns as the rows.`);
      return;
    }

    this.setState({
      spreadsheet: this.state.spreadsheet.concat(rows.map(row => row.map(cell => ({ value: cell })))),
    });

    this.justAddedRow = true;
  }

  deleteRow (idx) {
    console.log("call delete row")
    this.setState({
      spreadsheet: this.state.spreadsheet.slice(0, idx).concat(this.state.spreadsheet.slice(idx + 1))
    }, () => {

    });

    this.justDeletedRow = true;
    save.removeRow(this.state.id, idx);
    this.setState({ lastSaved: new Date() });
  }

  addColumn () {
    this.justAddedColumn = true;
    this.setState({
      spreadsheet: this.state.spreadsheet.map(o => o.concat({ value: "" })),
      columns: this.state.columns.concat({ label: "", type: "Text" }),
    }, () => {
      save.columns(this.state.id, this.state.columns);
      this.state.spreadsheet.map((o, i) => {
        save.row(this.state.id, o, i);
      });
      this.setState({ lastSaved: new Date() });
    });
  }

  openDropdown (e) {
    let $select = e.target.parentNode.querySelector("select");
    console.log($select);
    $select.show();
  }

  updateColumnAttributes (idx, attrs) {
    let old = this.state.columns[idx];

    if (attrs.type === "Select" && old.type !== "Select" && (!old.config || !old.config.selectOptions)) {
      let possible_keys = this.state.spreadsheet
        .map((o) => o[idx].value)
        .filter(o => o && o.length > 0);
      let keys = possible_keys.filter(function(item, pos) {
        return possible_keys.indexOf(item) == pos;
      });

      console.log("chosen select options", keys)

      if (!old.config) old.config = {};
      attrs = {...attrs, config: {...old.config, selectOptions: keys }};
    }

    this.setState({
      columns: [...this.state.columns].map((o, i) => {
        return (idx === i) ? Object.assign(o, attrs) : o;
      }),
    }, () => {
      save.columns(this.state.id, this.state.columns);
    });
  }

  setCellValue (row, col, value) {
    this.setState({
      spreadsheet: this.state.spreadsheet.map((o, r) => {
        if (r === row) {
          return o.map((cell, c) => {
            if (c === col) {
              return Object.assign(cell, { value });
            } else return cell;
          });
        } else { return o }
      }),
    }, () => {
      save.row(this.state.id, this.state.spreadsheet[row], row);
      this.setState({ lastSaved: new Date() });
    });
  }

  getCellValue (row, col) {
    return this.state.spreadsheet[row][col].value;
  }

  closePopups (e) {
    if (!closest(e.target, ".expand"))
      document.querySelectorAll(".expand").forEach(el => el.classList.remove("expand"))
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.active) return;

    const { table } = this;
    setTimeout(() => {
      if (table.classList.contains("inactive--slide-in")) {
        table.classList.remove("inactive--slide-in");
        this.props.removeLastActive();
        setTimeout(() => this.setState({ doRender: true }), 300);
      }
    }, 300);

    let flag = false;
    ["justDeletedRow", "justAddedRow", "justAddedColumn"].map(o => {
      if (!flag && this[o]) {
        this[o] = false;
        flag = true;
      }
    })

    Object.entries(this.state).forEach(([key, val]) =>
      prevState[key] !== val && console.log(`State '${key}' changed`)
    );
  }

  formatTime () {
    if (this.state.lastSaved.constructor === Date) {
      return this.state.lastSaved.toTimeString().substr(0, 5);
    }
  }

  componentDidMount () {
    if (this.props.active) {
      if (this.table.classList.contains("inactive--slide-in")) {
        this.table.classList.remove("inactive--slide-in")
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.active === false && this.props.active === true) this.setState({ doRender: false });
    return true;
  }

  setPortal () {
    return ReactDOM.createPortal(
      <InformationSidebar show={this.state.show_information_sidebar} close={e => this.closeInformationSidebar(e)}>
        <InformationSidebarTable
          spreadsheetName={this.state.name}
          row_idx={this.state.informationSidebarRowIdx}
          columns={this.state.columns}
          setCellValue={this.setCellValue.bind(this)}
          getCellValue={this.getCellValue.bind(this)}
          onCellMouseDown={this.onCellMouseDown.bind(this)}
          onCellMouseMove={this.onCellMouseMove.bind(this)}
          onCellMouseUp={this.onCellMouseUp.bind(this)}
          spreadsheet={this.state.spreadsheet}
          addRow={this.addRow.bind(this)}
          deleteRow={this.deleteRow.bind(this)}
          setInformationSidebar={row => this.setInformationSidebar(row)}
          hideAddRow={true}
          hideDeleteRow={true}
        />
      </InformationSidebar>,
      document.querySelector(".overlay-wrapper"),
    );
  }

  setInformationSidebar (row, row_idx) {
    this.props.setInformationSidebar(true);
    this.setState({ show_information_sidebar: true, informationSidebarRowIdx: row_idx });
  }

  closeInformationSidebar (target) {
    this.setState({ show_information_sidebar: false });
    this.props.closeInformationSidebar(target);
  }

  saveColumnAttribute (column_idx, obj) {
    this.setState({
      columns: this.state.columns.map((column, idx) => {
        if (idx !== column_idx) return column;
        let config = Object.assign({}, column.config, obj);
        return Object.assign({}, column, { config });
      }),
    }, () => {
      save.columns(this.state.id, this.state.columns);
      this.setState({ lastSaved: new Date() });
    });
  }

  toggleDashboard (e) {
    const width = this.state.show_table ? "" : this.table.clientWidth;
    this.setState({ show_table: !this.state.show_table, width });
  }

  addCSVSheet () {
    let self = this;

    this.file_upload.click();
    this.file_upload.onchange = function () {
      const reader = new FileReader()
      reader.onload = event => {
        const { result } = event.target;

        let columns, rows, payload = result.split(/\n/);
        if (/\t/.test(payload[0])) {
          columns = payload[0].split(/\t/).map(o => ({ label: o, type: "Text" }));
          rows = payload.slice(1).map(row => row.split(/\t/));
        } else {
          columns = payload[0].split(/,/).map(o => ({ label: o, type: "Text" }));
          rows = payload.slice(1).map(row => row.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/).map(o => {
            if (/,/.test(o)) return o.replace(/^"|"$/g, "");
            return o;
          }));
        }

        self.props.addSheetWithData(columns, rows);
      };
      reader.onerror = error => reject(error)
      reader.readAsText(this.files[0]);
    }
  }

  render () {
    if (!this.props.active && !this.props.lastActiveSheet) {
      return (
        <div
          className={"table inactive p" + this.props.position}
          style={{ left: `-${this.props.position * 40}px`, zIndex: 10 - this.props.position, WebkitFilter: `blur(${this.props.position}px)` }}
          onClick={this.props.onClick}
        >
          <div className="sheet-name">{ this.state.name }</div>
        </div>
      )
    }

    const Portal = this.setPortal();

    return (
      <div
        ref={node => this.table = node}
        className={"table active inactive--slide-in sheet-" + this.state.id}
        onClick={e => this.closePopups(e)}
        style={{ width: this.state.width ? this.state.width + "px" : "", left: this.lastActiveSheet ? `-${this.props.position * 30}px` : 0, zIndex: this.lastActiveSheet ? 10 - this.props.position : 20 }}
      >
        <div className="diagnostics">
          <button className="button button--small-label button--blue dashboard-button" onClick={e => this.toggleDashboard(e)}>{ this.state.show_table ? "Spreadsheet" : "Dashboard" }</button>
          <label className={"last-saved button button--small-label " + (this.state.lastSaved ? "show" : "")}>
            { this.state.lastSaved ? "Saved at " + this.formatTime(this.state.lastSaved) : "No Changes" }
          </label>
        </div>
        <div className="sheet-name" contentEditable="true" onBlur={e => this.setSheetName(e)}>{ this.state.name }</div>
        { Portal }
          <div className="row header">
            <span className="cell-wrapper">
              <div className={"cell cell--action preview"}></div>
            </span>
          { this.state.columns.map((o, idx) => {
              const Icon = icons[this.state.columns[idx].type];

              let align = idx === 0 ? "left" : (idx === this.state.columns.length - 1 ? "right" : undefined);
              return (
                <span className="cell-wrapper" key={idx}>
                  <div
                    className={"cell cell-" + idx}
                    onMouseDown={e => this.onCellMouseDown(e, idx)}
                    onMouseMove={e => this.onCellMouseMove(e, idx)}
                    onMouseUp={e => this.onCellMouseUp()}
                  >
                    <TableHeaderSelect
                      align={align}
                      onChange={option => this.updateColumnAttributes(idx, { type: option })}
                      defaultValue={this.state.columns[idx].type}
                      columns={this.state.columns}
                      column_idx={idx}
                      icon={Icon}
                      saveColumnAttribute={this.saveColumnAttribute.bind(this)}
                    />
                    <span data-placeholder={"Col. " + (idx + 1)} onBlur={e => this.updateColumnAttributes(idx, { label: e.target.innerText })} contentEditable="true">{this.state.columns[idx].label}</span>
                    <div className="resize-me"></div>
                  </div>
                </span>
              );
            }
          ) }
            <span className="cell-wrapper" onClick={() => this.addColumn()}>
              <div className={"cell cell--action"}>+</div>
            </span>
          </div>
          <LoadTable show={!this.state.doRender} />
          <TableRows
            columns={this.state.columns}
            setCellValue={this.setCellValue.bind(this)}
            getCellValue={this.getCellValue.bind(this)}
            onCellMouseDown={this.onCellMouseDown.bind(this)}
            onCellMouseMove={this.onCellMouseMove.bind(this)}
            onCellMouseUp={this.onCellMouseUp.bind(this)}
            spreadsheet={this.state.doRender ? this.state.spreadsheet : []}
            addRow={this.addRow.bind(this)}
            deleteRow={this.deleteRow.bind(this)}
            setInformationSidebar={(row, row_idx) => this.setInformationSidebar(row, row_idx)}
            saveColumnAttribute={this.saveColumnAttribute.bind(this)}
          />
          <button className="button button--add-button csv" onClick={() => this.addCSVSheet()}>.CSV</button>
          <button className="button button--add-button" onClick={() => this.props.addSheet()}>+</button>
        <SpreadsheetDashboard
          spreadsheet={[...this.state.spreadsheet]}
          columns={this.state.columns} show={!this.state.show_table}
          // these can be filtered and sorted so we want to find the real row this represents.
          setInformationSidebar={(row, row_idx) => {
            let real_idx = this.state.spreadsheet.findIndex(o => o === row);
            this.setInformationSidebar(row, real_idx)
          }}
        />
      </div>
    );
  }
}
