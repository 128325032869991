import TextIcon from '@material-ui/icons/ShortTextOutlined';
import LongTextIcon from '@material-ui/icons/NotesOutlined';
import NumberIcon from '@material-ui/icons/BarChartOutlined';
import CheckboxIcon from '@material-ui/icons/CheckBoxOutlined';
import PhoneNumberIcon from '@material-ui/icons/SmartphoneOutlined';
import LatLongIcon from '@material-ui/icons/MyLocationOutlined';
import ComputedIcon from '@material-ui/icons/FunctionsOutlined';
import OpenIcon from '@material-ui/icons/OpenInNew';
import TagsIcon from '@material-ui/icons/LabelOutlined';
import SelectIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import DateIcon from '@material-ui/icons/CalendarToday';

import LeftAlignIcon from '@material-ui/icons/FormatAlignLeftOutlined';
import CenterAlignIcon from '@material-ui/icons/FormatAlignCenterOutlined';
import RightAlignIcon from '@material-ui/icons/FormatAlignRightOutlined';
import FormatBoldIcon from '@material-ui/icons/FormatBoldOutlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalicOutlined';

import StarIcon from '@material-ui/icons/Star';
import EmptyStarIcon from '@material-ui/icons/StarBorder';

export default {
  Text: TextIcon,
  "Long Text": LongTextIcon,
  "Number": NumberIcon,
  "Checkbox": CheckboxIcon,
  "Phone Number": PhoneNumberIcon,
  "Lat/Long": LatLongIcon,
  "Computed": ComputedIcon,
  "Tags": TagsIcon,
  "Select": SelectIcon,
  "Rating": EmptyStarIcon,
  "Date": DateIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  FormatBoldIcon,
  FormatItalicIcon,
  OpenIcon,
  StarIcon,
  EmptyStarIcon
};
