export default function computeStyleFromConfig (config) {
  if (!config) return {};
  let styles = {};

  if (config.italic) styles.fontStyle = "italic";
  if (config.bold) styles.fontWeight = "600";
  if (config.alignment) styles.textAlign = config.alignment;

  return styles;
}
