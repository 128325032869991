import React from 'react';
import Waves from "../modules/Waves.js";

export default class Notebooks extends React.Component {
  constructor () {
    super();
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.active && this.props.active) {
      setTimeout(function () {
        document.querySelector(".notebooks").classList.add("hide");
      }, 1000);
    }

    let width = 10;
    const colors = ["#111120", "#0a1035", "#350a18", "#240f33"];

    document.querySelectorAll(`[data-canvas]:not(.rendered)`).forEach((canvas, idx) => {
      canvas.width = 850 * 1.5;
      canvas.height = 1100 * 1.5;

      canvas.style.backgroundColor = colors[idx % colors.length];

      let notebook_key = canvas.dataset.canvas;
      let notebook = this.props.notebooks.find(notebook => notebook.notebook_key === notebook_key);

      if (notebook.art) {
        let waves = new Waves({
          width, iterations: 300, padding: 50, offset: 65, canvas,
        }).cached(JSON.parse(notebook.art)).draw();
      } else {
        let waves = new Waves({
          width, iterations: 300, padding: 50, offset: 65, canvas,
        }).run();
        const grid = [...waves.grid];
        //grid = grid.map((o, i) => i < grid.length - 100 ? )
        this.props.setNotebookArt(notebook_key, JSON.stringify(waves.grid));
      }

      canvas.classList.add("rendered");
    });
  }

  openNotebookLink (notebook_key) {
    history.pushState({}, null, `/notebooks/${notebook_key.replace("notebook_", "")}`);
  }

  goBackToNotebooks () {
    history.pushState({}, null, `/`);
  }

  activeNotebookObject () {
    return this.props.notebooks.find(notebook => this.props.activeNotebook === notebook.notebook_key);
  }

  setNotebookName (e) {
    let name = e.target.innerText.trim();
    this.props.setNotebookName(this.props.activeNotebook, name);
  }

  render () {
    let activeNotebook = this.activeNotebookObject();

    return (
      <>
        { this.props.active &&
          <div className="notebook-utils">
            <div className="button button--small-label back-to-notebooks" onClick={() => this.goBackToNotebooks()}>← Back to Notebooks</div>
            <div className="notebook-name" contentEditable="true" data-placeholder="Notebook Name" onBlur={e => this.setNotebookName(e)}>{ activeNotebook ? activeNotebook.name : "" }</div>
          </div>
        }
        <div className={"notebooks " + (this.props.active ? "collapse" : "")}>
          <h1>Your Notebooks</h1>
          { this.props.notebooks.map((notebook, idx) => {
              return (
                <div className="notebook" onClick={() => this.openNotebookLink(notebook.notebook_key)}>
                  <canvas data-canvas={notebook.notebook_key}></canvas>
                  { this.props.activeNotebook === notebook.notebook_key && <div className="active-badge button button--small-label">Active</div> }
                  <div className="info">
                    <h1>{ notebook.name }</h1>
                    <div className="num-sheets">{ notebook.sheets.length === 1 ? `1 Spreadsheet` : `${notebook.sheets.length} Spreadsheets` }</div>
                    <div className="open">Open →</div>
                  </div>
                </div>
              )
            }) }
          <div className="notebook add-notebook" onClick={() => this.props.addNotebook()}>+</div>
        </div>
      </>
    );
  }
}
