import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js'

import FormattedText from "../../modules/FormattedText.js";
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

export default class LatLong extends Cell {
  constructor () {
    super();

    this.state = {

    }

    this.nodes = {
      lat: null,
      lon: null,
    };

    this.parent = null;
  }

  componentDidMount () {
    const { value } = this.props;
    console.log(this.props)

    value.match(/^\([\d.]+\),/g)
    let lat = value.match(/^\((-*[\d.]+)/);
    let lon = value.match(/(-*[\d.]+)[A-Za-z]\)$/);

    this.setState({
      lat: lat ? lat[1] : "",
      lon: lon ? lon[1] : "",
    });
  }

  componentDidUpdate (prev) {
    if (prev.value !== this.props.value) {
      this.componentDidMount();
    }
  }

  save (e) {
    const { target } = e.nativeEvent,
          { parentNode } = target;

    this.setState({
      [target.dataset.name]: target.innerText,
    });

    let lat = parseFloat(parentNode.querySelector("[data-name='lat']").innerText.trim()) || "_";
    let lon = parseFloat(parentNode.querySelector("[data-name='lon']").innerText.trim()) || "_";

    console.log(`(${lat}N, ${lon}E)`);

    this.props.save(`(${lat}N, ${lon}E)`);
  }

  render () {
    return (
      <>
        <span style={ computeStyleFromConfig(this.props.config) } ref={node => this.parent = node}>
          <span>(</span>
          <ContentEditable
            ref={e => { if (e) this.nodes.lat = e }}
            html={this.state.lat}
            style={{minWidth: "2ch", display: "inline-block"}}
            data-placeholder="0.00"
            data-after="N"
            data-name="lat"
            onChange={e => this.save(e)}
            tagName={"span"}
          />,&nbsp;
          <ContentEditable
            ref={e => { if (e) this.nodes.lon = e }}
            html={this.state.lon}
            style={{minWidth: "2ch", display: "inline-block"}}
            data-placeholder="0.00"
            data-after="E"
            data-name="lon"
            onChange={e => this.save(e)}
            tagName={"span"}
          />
          <span>)</span>
        </span>
      </>
    );
  }
}
