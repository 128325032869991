import React from 'react';
import ContentEditable from './third/react-contenteditable.js'

import Select from './Select.js';

import Icons from './Icons.js';
const { LeftAlignIcon, CenterAlignIcon, RightAlignIcon, FormatBoldIcon, FormatItalicIcon } = Icons;

class TableHeaderSelectOptions extends React.Component {
  constructor () {
    super();

    this.nodes = {};
  }

  save (column_idx, key, value) {
    this.props.saveColumnAttribute(column_idx, {
      [key]: value
    });
  }

  render () {
    const { type, column_idx, columns } = this.props;
    const config = columns[column_idx].config || {};

    return (
      <>
        <div className="select-configure--custom-fields" data-name={type}>
          <h3>{type}</h3>
        { (() => {
            if (type === "Text") {

            } else if (type === "Long Text") {

            } else if (type === "Number") {
              return (
                <div className="custom-fields--field-row">
                  <div className="field">
                    <label>Prefix</label>
                    <ContentEditable
                      html={config.prefix || ""}
                      onChange={e => this.save(column_idx, "prefix", e.target.value)}
                      data-placeholder="$"
                      tagName={"span"}
                    />
                  </div>
                  <div className="field">
                    <label>Suffix</label>
                    <ContentEditable
                      html={config.suffix || ""}
                      onChange={e => this.save(column_idx, "suffix", e.target.value)}
                      data-placeholder="°F"
                      tagName={"span"}
                    />
                  </div>
                </div>
              )
            } else if (type === "Checkbox") {

            } else if (type === "Phone Number") {

            } else if (type === "Lat/Long") {

            } else if (type === "Computed") {
              return (
                <div className="custom-fields--field-row">
                  <div className="field textarea computed">
                    <label>Formula</label>
                    <ContentEditable
                      html={config.computed || ""}
                      onBlur={e => this.save(column_idx, "computed", e.target.innerText)}
                      data-placeholder="col['a'] + col['b']"
                      tagName={"pre"}
                    />
                  </div>
                </div>
              )
            }
          })()
        }
          <div className="custom-fields--field-row">
            <div className="alignment-group">
              <LeftAlignIcon
                className={(!config.alignment || config.alignment) === "left" ? "selected" : ""}
                onClick={e => this.save(column_idx, "alignment", "left")}
              />
              <CenterAlignIcon
                className={config.alignment === "center" ? "selected" : ""}
                onClick={e => this.save(column_idx, "alignment", "center")}
              />
              <RightAlignIcon
                className={config.alignment === "right" ? "selected" : ""}
                onClick={e => this.save(column_idx, "alignment", "right")}
              />
            </div>
            <div className="format-group">
              <FormatBoldIcon
                className={config.bold ? "selected" : ""}
                onClick={e => this.save(column_idx, "bold", !config.bold)}
              />
              <FormatItalicIcon
                className={config.italic ? "selected" : ""}
                onClick={e => this.save(column_idx, "italic", !config.italic)}
              />
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }
}

export default class TableHeaderSelect extends React.Component {
  constructor () {
    super();

    this.state = {
      selected: null,
    };
  }

  onChange (option) {
    this.setState({ selected: option });
    this.props.onChange(option);
  }

  render () {
    return (
      <>
        <Select
          onChange={option => this.onChange(option)}
          options={["Text", "Long Text", "Number", "Rating", "Select", "Tags", "Date", "Checkbox", "Phone Number", "Lat/Long", "Computed"]}
          defaultValue={this.props.defaultValue}
          icon={this.props.icon}
          showIcon={true}
          align={this.props.align}
          customOptionBody={
            <TableHeaderSelectOptions
              type={this.props.defaultValue}
              column_idx={this.props.column_idx}
              columns={this.props.columns}
              saveColumnAttribute={this.props.saveColumnAttribute}
            />}
        />
      </>
    )
  }
}
