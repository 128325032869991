import TextEdit from "./TextEdit.js";

export default class FormattedText {
  constructor (el, template) {
    this.el = el;
    this.template = template;
    this.callbacks = {};
    this.styles = {};

    if (!this.el.classList.contains("formatted-text--parent")) {
      this.el.classList.add("formatted-text--parent");
    }
  }

  templateToBlocks (template) {
    let blocks = [];
    let block = null;
    let block_type = null;
    for (let x = 0; x < template.length; x++) {
      if (block_type !== ((template[x] === "d") ? "input" : "template")) {
        if (block) {
          blocks.push(block);
          block = null;
        }
        block_type = null;
      }

      if (!block) {
        block_type = (template[x] === "d") ? "input" : "template";
        if (block_type === "input") block = 1;
        else block = template[x];
        continue;
      }

      if (block_type === "input") block++;
      else block += template[x];
    }

    if (block) {
      blocks.push(block);
    }

    return blocks;
  }

  placeholder (blocks) {
    this.placeholder = blocks;
    return this;
  }

  blocksToHTML (blocks) {
    let counter = 0;

    const placeholder = this.placeholder || [];

    return blocks.map(o => {
      if (typeof o === "string") {
        return `<span class="formatted-text--template">${o}</span>`;
      } else {
        let str = `<span class="formatted-text--input" data-idx=${counter} contenteditable="true" data-placeholder="${placeholder[counter] || ""}" data-max-length="${o}"></span>`;
        counter++;
        return str;
      }
    });
  }

  style (obj) {
    this.styles = obj;
    return this;
  }

  applyStyles (obj) {
    if (obj.parent) {
      Object.keys(obj.parent).forEach(key => {
        this.el.style[key] = obj.parent[key];
      });
    }

    if (obj.input) {
      Object.keys(obj.input).forEach(key => {
        this.getInputBlocks().forEach(block => block.style[key] = obj.input[key]);
      });
    }

    if (obj.template) {
      Object.keys(obj.template).forEach(key => {
        this.getTemplateBlocks().forEach(template => template.style[key] = obj.template[key]);
      });
    }
  }

  addEvents (el) {
    let inputs = el.querySelectorAll(".formatted-text--input");
    let furthest_elem = 0;

    let self = this;
    inputs.forEach(input => input.addEventListener("keydown", function (e) {
      // detect tab, and skip out on anything custom.
      if (e.keyCode === 9) {
        return;
      }
      if (self.callbacks.validate && !self.callbacks.validate(e.key) && [8,13,37,39].indexOf(e.keyCode)) {
        e.preventDefault();
        return;
      }

      let caret = TextEdit.getCurrentCursorPosition(this);

      if (caret >= (+this.dataset.maxLength - 1) && caret === this.innerText.length) {
        if (e.keyCode === 8) {
          return;
        }

        if ((+this.dataset.idx < inputs.length - 1 && e.key.length === 1) || e.keyCode === 39) {
          let next = el.querySelector("[data-idx='" + (+this.dataset.idx + 1) + "']");
          if (caret > (+this.dataset.maxLength - 1)) {
            if (next.innerText.length > 0) {
              e.preventDefault();
              setTimeout(function () {
                next.focus();
              }, 0);
              return;
            } else {
              e.preventDefault();
              setTimeout(function () {
                next.focus();
                if (e.key.length === 1) next.innerText = e.key;
                TextEdit.setCurrentCursorPosition(next, next.innerText.length);
              }, 0);
              return;
            }
          } else {
            setTimeout(function () {
              next.focus();
            }, 0);
          }
        }
      } else if (caret === 0 && (e.keyCode === 8 || e.keyCode === 37)) {
        if (+this.dataset.idx > 0) {
          let prev = el.querySelector("[data-idx='" + (+this.dataset.idx - 1) + "']");
          TextEdit.setCurrentCursorPosition(prev, prev.innerText.length);
          e.preventDefault();
          return;
        }
      }

      if (this.innerText.length >= (+this.dataset.maxLength) && e.key.length === 1) {
        e.preventDefault();
        return;
      }
    }))

    inputs.forEach(input => input.addEventListener("input", (e) => {
      if (this.callbacks.change) {
        this.callbacks.change(this.el.value);
        var event = new Event('input');
        this.el.dispatchEvent(event);
      }
    }));
  }

  getInputBlocks () {
    return Array.prototype.slice.call(this.el.querySelectorAll(".formatted-text--input"))
  }

  getTemplateBlocks () {
    return Array.prototype.slice.call(this.el.querySelectorAll(".formatted-text--template"))
  }

  getTotalCaretPosition () {
    const input_blocks = this.getInputBlocks();
    let block_idx = input_blocks.findIndex(o => o === document.activeElement);
    if (document.activeElement && block_idx > -1) {
      let caret = TextEdit.getCurrentCursorPosition(document.activeElement);
      let total_caret = input_blocks.slice(0, block_idx).reduce((a, b) => a + (+b.dataset.maxLength), 0) + caret;
      return total_caret;
    }
  }

  setRelativeCaretPositionFromTotal (total) {
    const input_blocks = this.getInputBlocks();
    let counter = 0;
    for (let x = 0; x < input_blocks.length; x++) {
      let block = input_blocks[x];
      if (counter + (+block.dataset.maxLength) >= total) {
        TextEdit.setCurrentCursorPosition(block, total - counter);
        return;
      }
      counter += +block.dataset.maxLength;
    }
  }

  update (template) {
    this.template = template;
    let value = this.el.value;
    let total_caret = this.getTotalCaretPosition();
    this.render();
    this.el.value = value;
    this.setRelativeCaretPositionFromTotal(total_caret);
  }

  validate (cb) {
    if (cb === "number") {
      this.callbacks.validate = char => {
        return /[0-9]/.test(char);
      };
    } else if (cb === "hex") {
      this.callbacks.validate = char => {
        return /[0-9A-Fa-f]/.test(char);
      };
    } else {
      this.callbacks.validate = cb;
    }
    return this;
  }

  on (event, cb) {
    this.callbacks[event] = cb;
    return this;
  }

  render () {
    const blocks = this.templateToBlocks(this.template);
    const html = this.blocksToHTML(blocks);

    this.el.innerHTML = "";
    this.el.innerHTML = html.join("\n");
    this.addEvents(this.el);

    const self = this;
    if (!this.el.__modified) {
      Object.defineProperty(this.el, "value", {
        get (val) {
          return self.getInputBlocks().reduce((a, b) => a + b.innerText, "");
        },
        set (val) {
          let counter = 0;
          let input_blocks = self.getInputBlocks();
          for (let x = 0; x < input_blocks.length; x++) {
            let block = input_blocks[x];
            let len = +block.dataset.maxLength;
            block.innerText = val.substr(counter, len);
            counter = counter + len;
            if (counter === val.length) break;
          }
        }
      });
      this.el.__modified = true;
    }

    this.applyStyles(this.styles);

    return this;
  }
}
