import React from 'react';

import Compute from '../../modules/Compute.js';
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

export default class Computed extends React.Component {
  constructor () {
    super();
  }

  save (e) {
    this.props.save(e.target.value);
  }

  render () {
    let obj = {};
    {
      this.props.columns.forEach((col, idx) => {
        if (col.type === "Number") {
          obj[col.label] = +this.props.row[idx];
        } else {
          obj[col.label] = this.props.row[idx];
        }
      });
    }

    return (
      <span
        style={ computeStyleFromConfig(this.props.config) }
        dangerouslySetInnerHTML={{ __html: Compute(obj, this.props.config.computed) }}
      ></span>
    );
  }
}
