import React from 'react';

import Waves from "../modules/Waves.js";
import "../../css/login.css";

import cookie from "../modules/Cookie.js";
const auth_token_path = "spreadsheet/user/auth_token";

export default class Login extends React.Component {
  constructor () {
    super();

    this.state = { mode: "Register", error: null };
  }

  componentDidMount () {
    let width = Math.floor((window.innerWidth / window.innerHeight) * 15);
    console.log("update");
    let canvas = document.querySelector("canvas");
    canvas.width = 2500;
    canvas.height = 2000;
    let waves = new Waves({
      width, iterations: 300, padding: 0
    }).run();

    waves.mutate();
    let idx = 0;
    let sign = true;
    setInterval(() => {
      if (sign) idx++;
      else idx--;

      waves.draw(idx / 15);
      if (idx > 200) {
        sign = !sign;
      } else if (idx < -200) {
        sign = !sign;
      }
    }, 40);
  }

  async submit () {
    let email = document.querySelector("[name='email']").value.trim();
    let password = document.querySelector("[name='password']").value.trim();

    if (this.state.mode === "Register") {
      let response = await (await fetch(`/api/users/`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({ email, password }),
      })).json();

      if (response.name === "SequelizeUniqueConstraintError") {
        return this.setState({ error: `The email ${email} is already taken.`});
      }

      cookie.set(auth_token_path, response.private_key);
      this.setState({ success: true }, () => setTimeout(() => window.location.href = "/",300));
    } else if (this.state.mode === "Login") {
      let response = await (await fetch(`/api/users/sessions/`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({ email, password }),
      })).json();

      if (response.error) {
        return this.setState({ error: response.error });
      }

      cookie.set(auth_token_path, response.private_key);
      this.setState({ success: true }, () => setTimeout(() => window.location.href = "/",300));
    }
  }

  setMode (mode) {
    this.setState({ mode });
  }

  render () {
    return (
      <div id="login">
        <div className="canvas-wrapper">
          <canvas width="300" height="500"></canvas>
        </div>
        <div id="login-modal" className={this.state.mode.toLowerCase() + " " + (this.state.success ? "fade-out" : "")}>
          <h1 className="h1--title">{ this.state.mode }</h1>
          <div className="switch">
            <div className="slider" style={{ backgroundColor: this.state.mode === "Register" ? "#eb4f7d" : "#ff6262", transform: this.state.mode === "Register" ? "translateX(100px)" : "translateX(0px)" }}></div>
            <label className={"switch--login " + (this.state.mode === "Login" ? "active" : "")} onClick={() => this.setMode("Login")}>Login</label>
            <label className={"switch--register " + (this.state.mode === "Register" ? "active" : "")} onClick={() => this.setMode("Register")}>Register</label>
          </div>
          <div className="input-box email">
            <label>Email</label>
            <input type="email" name="email" placeholder="you@company.com" autocomplete="new-password" required />
          </div>
          <div className="input-box password">
            <label>Password</label>
            <input type="password" name="password" placeholder="password" autocomplete="new-password" required />
          </div>
          <div className="error">{ !this.state.success ? this.state.error : `` }</div>
          <h1 className="h1--button" onClick={() => this.submit()}>Let's go →</h1>
        </div>
      </div>
    );
  }
}
