import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js';

import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

export default class LongText extends Cell {
  constructor () {
    super();
  }

  onFocus (e) {
    let parent = this.cell.getEl().parentNode;
    if (!parent.classList.contains("expand")) parent.classList.add("expand");
  }

  onBlur (e) {
    let parent = this.cell.getEl().parentNode;
    parent.classList.remove("expand");
    this.props.save(e.target.innerText);
  }

  render () {
    return (
      <ContentEditable
        style={ computeStyleFromConfig(this.props.config) }
        ref={cell => this.cell = cell}
        html={this.props.value}
        onFocus={e => this.onFocus(e)}
        onBlur={e => this.onBlur(e)}
        tagName={"span"}
      />
    );
  }
}
