import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js';

import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

const hexToArray = hex => hex.replace("#", "").match(/.{2}/g).map(n => parseInt(n, 16));
const arrayToHex = array => "#" + array.map(o => ("0" + o.toString(16)).slice(-2)).join("")
const gradiateColors = (color2, color1, weight) => {
  if (typeof color2 === "string") color2 = hexToArray(color2);
  if (typeof color1 === "string") color1 = hexToArray(color1);

  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
  return arrayToHex(rgb);
};

export default class Range extends Cell {
  constructor () {
    super();
  }

  save (e) {
    this.props.save(e.target.value);
  }

  render () {
    const { rangeValues } = this.props;

    const blue = "#5680c3";
    const red = "#c85f5f";

    let rangeValue = (+this.props.value - rangeValues.min) / (rangeValues.max - rangeValues.min);

    const style = Object.assign(computeStyleFromConfig(this.props.config), { color: gradiateColors(blue, red, rangeValue) });

    return (
      <ContentEditable
        style={ computeStyleFromConfig(this.props.config) }
        html={this.props.value + ""}
        onChange={e => this.save(e)}
        tagName={"span"}
        data-prefix={this.props.config.prefix}
        data-suffix={this.props.config.suffix}
        style={style}
      />
    );
  }
}
