import React from 'react';
import { matchPath } from "react-router";

import Spreadsheet from "../components/Spreadsheet.js";
import InformationSidebar from "../components/InformationSidebar.js";
import Notebooks from "../components/Notebooks.js";

import cookie from "../modules/Cookie.js";
const auth_token_path = "spreadsheet/user/auth_token";

const generateID = () => Math.random().toString(16).split(/\./).pop();

export default class MainApp extends React.Component {
  constructor () {
    super();

    this.state = {
      lastActiveSheet: null,
      display: true,
      activeSheet: null,
      sheets: null,
      showInformationSidebar: false,
      notebooks: [],
      show_app: false,
    };

    (async () => {
      let notebooks = await (await fetch(`/api/users/me/`, {
        method: 'GET',             // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',         // manual, *follow, error
        referrer: 'no-referrer',    // no-referrer, *client
      })).json();

      notebooks.sort((a, b) => a.id > b.id ? 1 : -1);

      let activeNotebook;
      if (/\/notebooks\/(.+)\//.test(location.pathname)) {
        activeNotebook = `notebook_${location.pathname.match(/\/notebooks\/(.+)\//)[1]}`;
      }

      this.setState({ notebooks, activeNotebook }, async () => {
        if (/\/notebooks\/(.+)\//.test(location.pathname)) {
          await this.openNotebook(activeNotebook);
        }

        this.setState({ show_app: true });
      });
    })();

    this.overlay_wrapper = null;
  }

  componentDidMount () {
    window.onpopstate = (e) => {
      if (matchPath(location.pathname, { path: "/notebooks/:notebook_key" })) {
        let { notebook_key } = matchPath(location.pathname, { path: "/notebooks/:notebook_key" }).params;
        console.log(notebook_key)
        this.openNotebook(`notebook_${notebook_key}`);
      } else if (matchPath(location.pathname, { path: "/" })) {
        this.setState({
          activeNotebook: false,
          sheets: null,
        });
      }
    };

    history.onpushstate = window.onpopstate;
    window.onpopstate();
  }

  removeLastActive () {
    const el = document.querySelector(".sheet-" + this.state.lastActiveSheet.replace(/\s+/g, "_"));
    el.classList.add("inactive--slide-out");
    setTimeout(() => {
      el.classList.remove("inactive--slide-out");
      this.setState({
        lastActiveSheet: null,
      });
      el.style.width = ""
    }, 300);
  }

  changeSheets (e, id) {
    const { target } = e;
    this.setState({
      activeSheet: id,
      lastActiveSheet: this.state.activeSheet,
    });

    setTimeout(() => {
      target.classList.add("inactive--slide-in");
    }, 300);
  }

  processSheet (sheet) {
    const rows = sheet.rows.length > 0 ?
      sheet.rows.sort((a, b) => a.row_idx > b.row_idx ? 1 : -1).map(row => {
        // remove this after migrating production.
        // this is a carry over for when these were stringified.
        row.data = typeof row.data === "string" ? JSON.parse(row.data) : row.data;
        return row.data.map(o => ({ value: o }))
      }) :
      new Array(3).fill(0).map(o => new Array(5).fill(0).map(_ => ({ value: `` })));

    return {
      name: sheet.spreadsheet.name,
      id: sheet.spreadsheet.spreadsheet_key,
      oldState: {
        columns: sheet.columns ? JSON.parse(sheet.columns.data) : new Array(5).fill(0).map(col => ({ label: ``, type: `Text` })),
        spreadsheet: rows,
      },
    };
  }

  async openNotebook (notebook_key) {
    let spreadsheets = await (await fetch(`/api/notebooks/${notebook_key}/spreadsheets/`, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    })).json();


    let sheets = spreadsheets.map(sheet => {
      const columns = sheet.columns ? JSON.parse(sheet.columns.data) : new Array(5).fill(0).map(col => ({ label: ``, type: `Text` }));
      return this.processSheet(sheet);
    });

    if (document.querySelector(".table-container")) {
      document.querySelector(".table-container").classList.add("animate-in");
      setTimeout(() => {
        // if we don't null it out first it will not rerender the sheets. Terrible!
        this.setState({ sheets: [] });
        this.setState({ sheets, activeSheet: sheets[0].id, activeNotebook: notebook_key }, () => console.log(this.state));
      }, 300);
    } else {
      // if we don't null it out first it will not rerender the sheets. Terrible!
      this.setState({ sheets: [] });
      this.setState({ sheets, activeSheet: sheets[0].id, activeNotebook: notebook_key }, () => console.log(this.state));
    }
  }

  async addNotebook () {
    let notebook = await (await fetch(`/api/notebooks/`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    })).json();

    this.setState({ notebooks: this.state.notebooks.concat(notebook) });
  }

  async addSheet () {
    const sheet = await (await fetch(`/api/notebooks/${this.state.activeNotebook}/spreadsheets/`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    })).json();

    this.setState({
      notebooks: this.state.notebooks.map(notebook => {
        return notebook;
      }),
      sheets: this.state.sheets.concat(this.processSheet({ spreadsheet: sheet, rows: [] })),
    });
  }

  async addSheetWithData (columns, rows) {
    const sheet = await (await fetch(`/api/notebooks/${this.state.activeNotebook}/spreadsheets/`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    })).json();

    await fetch(`/api/spreadsheets/${sheet.spreadsheet_key}/columns/`, {
      method: 'POST',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
      body: JSON.stringify({
        data: JSON.stringify(columns),
      }), // body data type must match "Content-Type" header
    });

    await fetch(`/api/spreadsheets/${sheet.spreadsheet_key}/rows/`, {
      method: 'POST',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
      body: JSON.stringify({
        data: rows,
      }), // body data type must match "Content-Type" header
    });

    const updated_sheet = await (await fetch(`/api/spreadsheets/${sheet.spreadsheet_key}/`, {
      method: 'GET',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
    })).json();

    this.setState({
      notebooks: this.state.notebooks.map(notebook => {
        return notebook;
      }),
      sheets: this.state.sheets.concat(this.processSheet(updated_sheet)),
    });
  }

  async saveNotebookAttributes (notebook_key, attrs) {
    const notebook = await (await fetch(`/api/notebooks/${notebook_key}/`, {
      method: 'POST',             // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache',          // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',         // manual, *follow, error
      referrer: 'no-referrer',    // no-referrer, *client
      body: JSON.stringify(attrs), // body data type must match "Content-Type" header
    })).json();

    this.setState({
      notebooks: this.state.notebooks.map(old => {
        console.log(notebook.notebook_key, old.notebook_key)
        if (notebook.notebook_key === old.notebook_key) {
          return Object.assign(old, notebook);
        }
        return old;
      }),
    }, () => console.log(this.state.notebooks));
  }

  setInformationSidebar (body) {
    this.setState({
      informationSidebarBody: body,
      showInformationSidebar: true,
    });
  }

  closeInformationSidebar (target) {
    if (this.state.showInformationSidebar && target.classList.contains("overlay")) {
      this.setState({ showInformationSidebar: false });
    }
  }

  handleClick (e) {
    const possibly_hide = document.querySelectorAll(".focus, .expand");

    possibly_hide.forEach(node => {
      if (!node.contains(e.target)) {
        node.classList.remove("focus");
        node.classList.remove("expand");
      }
    });
  }

  render () {
    let position = 0;

    setTimeout(() => {
      document.querySelectorAll(".table-container.animate-in").forEach(o => o.classList.remove("animate-in"));
    }, 0);

    if (!cookie.get(auth_token_path)) {
      window.location.href = "/login/";
      return (<></>);
    }

    return (
      <div className={"app-wrapper " + (this.state.show_app ? "show" : "")}>
        <Notebooks
          notebooks={this.state.notebooks}
          active={!!this.state.activeNotebook}
          activeNotebook={this.state.activeNotebook}
          addNotebook={this.addNotebook.bind(this)}
          setNotebookName={(notebook_key, name) => this.saveNotebookAttributes(notebook_key, { name })}
          setNotebookArt={(notebook_key, art) => this.saveNotebookAttributes(notebook_key, { art })}
        />
        { this.state.sheets && <div className={"table-container " + (this.state.showInformationSidebar ? "slide-left" : "")} onClick={e => this.handleClick(e)}>
          { this.state.sheets.map((o, idx) => {
              if (o.id !== this.state.activeSheet) position++;
              return (
                <Spreadsheet
                  key={idx}
                  name={o.name}
                  id={o.id}
                  active={o.id === this.state.activeSheet}
                  lastActiveSheet={o.id === this.state.lastActiveSheet}
                  removeLastActive={() => this.removeLastActive()}
                  position={position}
                  onClick={(e) => this.changeSheets(e, o.id)}
                  oldState={console.log(o.oldState) || o.oldState}
                  addSheet={() => this.addSheet()}
                  setInformationSidebar={body => this.setInformationSidebar(body)}
                  overlay_wrapper={this.overlay_wrapper}
                  closeInformationSidebar={e => this.closeInformationSidebar(e)}
                  addSheetWithData={(columns, rows) => this.addSheetWithData(columns, rows)}
                />
              );
            }) }
        </div> }
      </div>
    );
  }
}
