import React from 'react';

import icons from './Icons.js';

export default class Select extends React.Component {
  constructor () {
    super();

    this.state = {
      selected: null,
    };
  }

  componentDidMount () {
    this.setState({ selected: this.props.defaultValue });
  }

  onChange (option) {
    this.setState({ selected: option });
    this.props.onChange(option);
  }

  render () {
    const ColumnIcon = this.props.icon;
    const { arrowPosition } = this.props;

    /* I don't know why I have to tell it to focus when I click it explicitly, but Safari makes me. */
    return (
      <>
        { this.props.icon && <ColumnIcon className="arrow" focusable="true" tabIndex="1" onClick={(e) => e.target.focus()} /> }
        <div className={"select-configure " + (this.props.show ? "show" : "") + (this.props.align ? ` align--${this.props.align}` : "")} onMouseDown={e => console.log(e)} tabIndex="0">
        { this.props.customOptionBody }
          <div className={"select-configure--arrow " + ((!arrowPosition || arrowPosition === "top") ? "top" : arrowPosition)}></div>
          <div className={"select-configure--options"}>
            { this.props.options.map(option => {
                const Icon = icons[option];
                return (
                  <div
                    className={"select-configure--option " + (this.state.selected === option ? "selected" : "")}
                    onClick={() => this.onChange(option)}
                  >
                    {this.props.showIcon && Icon && <Icon />}
                    <span className="label">{ option }</span>
                  </div>
                )
              }) }
          </div>
        </div>
      </>
    )
  }
}
