const round = (num, decimals) => {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

const Maps = {
  array: {
    cell: {
      ProportionTrue: (array) => {
        // proportion to max 2 decimal points.
        return round(100 * array.reduce((a, b) => a + b, 0) / array.length, 2) + "%";
      },

      sum: (array) => {
        return round(array.reduce((a, b) => a + b, 0), 2);
      },

      count: (array) => {
        return array.length;
      },

      min: (array) => {
        return round(Math.min.apply(Math, array), 2);
      },

      max: (array) => {
        return round(Math.max.apply(Math, array), 2);
      },

      range: (array) => {
        let min = Maps.array.cell.min(array);
        let max = Maps.array.cell.max(array);

        return round(max - min, 2);
      },

      mean: (array) => {
        return round(Maps.array.cell.sum(array) / Maps.array.cell.count(array), 2);
      },

      StandardDeviation: (array) => {
        const mean = Maps.array.cell.mean(array);
        return round(Math.sqrt(Maps.array.cell.mean(array.map(o => Math.pow(o - mean, 2)))), 2);
      },
    }
  }
};

const addOperation = (payload, operation) => {
  return payload.operations ? payload.operations.concat(operation) : [operation];
};

const Calculate = (payload, columns) => {
  return {
    Column: (column_name) => {
      const col = columns.findIndex(col => col.label === column_name);
      if (col === -1) {
        return console.warn(`Error: Column "${column_name}" does not exist.`);
      }

      if (["Number", "Rating", "Checkbox"].indexOf(columns[col].type) === -1) {
        return console.warn(`Error: Column "${column_name}" with type "${columns[col].type}" is not supported in "Calculate.Column".`)
      }

      payload.operations = addOperation(payload, `Column:${column_name}`);

      return {
        toCell: (func, exec) => {
          payload.operations = addOperation(payload, `toCell:${func}`);

          if (func === "proportionTrue" && columns[col].type !== "Checkbox") {
            console.warn(`Error: Column "${column_name}" must be of type "Checkbox" to use "proportionTrue".`)
          }

          if (payload.type === "spreadsheet") {
            const array = payload.data.map(o => +(o[col].value));

            if (Maps.array.cell[func]) {
              return {
                operations: payload.operations,
                data: {
                  label: func,
                  value: Maps.array.cell[func](array),
                },
              }
            } else if (exec) {
              return {
                operations: payload.operations,
                data: {
                  label: func,
                  value: new Function(exec)(array),
                },
              };
            }
          } else if (payload.type == "group_by") {
            if (Maps.array.cell[func]) {
              const obj = {};
              let data = payload.data.map(array => Maps.array.cell[func](array.map(o => +(o[col].value))));
              data = data.map((o, idx) => ({ value: o, label: payload.groups[idx] }))
              return {
                operations: payload.operations,
                data,
              }
            } else if (exec) {
              return payload.data.map(array => new Function(exec)(array));
            }
          }
        }
      };
    },

    GroupBy: (column_name) => {
      const col = columns.findIndex(col => col.label === column_name);
      if (col === -1) {
        return console.warn(`Error: Column "${column_name}" does not exist.`);
      }

      const obj = {};
      payload.data.forEach(row => {
        if (!obj[row[col].value]) obj[row[col].value] = [];
        obj[row[col].value].push(row);
      });

      const groups = Object.keys(obj);

      return Calculate({
        type: "group_by",
        groups,
        column_name,
        operations: addOperation(payload, `group_by:${column_name}`),
        data: groups.map(o => obj[o]),
      }, columns);
    },
  };
}

Calculate.Maps = Maps;


export default Calculate;
