import React from 'react';
import checkmark from "../../../assets/checkmark.png";
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';

export default class Checkbox extends React.Component {
  constructor () {
    super();

    this.state = {

    };

    this.completedAction = false;
  }

  save (e) {
    let value = e.target.checked;
    this.completedAction = true;
    this.setState({ value });
    this.props.save(value);
  }

  render () {
    const value = this.completedAction ? this.state.value : this.props.value;
    this.completedAction = false;

    return (
      <>
        <span style={ computeStyleFromConfig(this.props.config) } ref={cell => this.cell = cell}>
          <label tabIndex="1">
            <input
              type="checkbox"
              onChange={e => this.save(e)}
              checked={value}
            />
            <span className="cell--checkbox">
              <span style={{ backgroundImage: "url(" + checkmark + ")" }}></span>
            </span>
          </label>
        </span>
      </>
    );
  }
}
