const formData = $form => {
  if (typeof $form === "string") $form = document.querySelector($form);
  let data = {};
  $form.querySelectorAll("input, select, textarea").forEach(o => {
    if (o.type === "checkbox") {
      data[o.getAttribute("name")] = o.checked;
    } else {
      data[o.getAttribute("name")] = o.value.trim();
    }
  });

  return data;
};

export { formData };
