import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js';
import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

export default class Text extends Cell {
  constructor () {
    super();
  }

  save (e) {
    this.props.save(e.target.innerText);
  }

  render () {
    return (
      <ContentEditable
        style={ computeStyleFromConfig(this.props.config) }
        html={this.props.value}
        onBlur={e => this.save(e)}
        tagName={"span"}
      />
    );
  }
}
