import React from 'react';

import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Cell from "./Cell.js";

import Icons from "../../modules/Icons.js";
const { EmptyStarIcon, StarIcon } = Icons;

export default class Text extends Cell {
  constructor () {
    super();
  }

  save (e) {
    console.log('save');
    return;
    this.props.save(e.target.value + "");
  }

  render () {
    let value = parseInt(this.props.value, 10) || 0;

    return (
      <span className="stars">
        { new Array(Math.min(value, 5)).fill(0).map((o, idx) => {
            return <StarIcon className="star" onClick={() => this.props.save(idx + 1)} />
        }) }

        { new Array(Math.max(0, 5 - value)).fill(0).map((o, idx) => {
            return <EmptyStarIcon className="star star--empty" onClick={() => this.props.save(idx + value + 1)} />
        }) }
      </span>
    );
  }
}
