import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js';

import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Select from "../../modules/Select.js";

import Cell from "./Cell.js";

export default class Text extends Cell {
  constructor () {
    super();
    this.state = {
      show_dropdown: false,
    };
    this.add_tag_input = null;
    this.pseudo_focus = null;
    this.colors = ["#d17572", "#56acd0", "#df9b54", "#a769b6", "#55cb7a", "#d7c800"];
  }

  addPill (tag) {
    this.props.save(this.getPillsFromValue().concat(tag).join("; "));
    this.pseudo_focus.blur();
  }

  showRemainingOptions () {
    let tags = this.props.config.tags || [];
    let pills = this.getPillsFromValue();
    tags = tags.filter(o => pills.indexOf(o) === -1);

    if (tags.length === 0 && this.pseudo_focus) this.pseudo_focus.blur();
    return tags;
  }

  getPillsFromValue () {
    if (this.props.value.length === 0) return [];
    return this.props.value.split(/;\s/g);
  }

  deletePill (e, idx) {
    if (e.target.previousSibling) e.target.previousSibling.focus();
    if (e.keyCode === 8) {
      let values = this.getPillsFromValue();
      values.splice(idx, 1);
      this.props.save(values.join("; "));
    }
  }

  addTag () {
    const tags = this.props.config.tags || [];
    let val = this.add_tag_input.innerText.trim();
    if (val.length > 0 && tags.indexOf(val) === -1) {
      this.props.addTag(tags.concat(val));
      this.add_tag_input.innerText = "";
    } else if (tags.indexOf(val) !== -1 && this.getPillsFromValue().indexOf(val) === -1) {
      // the pill already exists, so they're probably just trying to type it.
      this.addPill(val);
    }
  }

  getTagColor (tag) {
    const idx = (this.props.config.tags || []).indexOf(tag);
    if (idx !== -1) {
      return this.colors[idx % this.colors.length];
    }
  }

  preventEnterAndAddTag (e) {
    e.preventDefault();
    this.addTag();
  }

  onClick (e) {
    e.target.closest(".row").classList.add("focus");
    e.target.closest(".cell").classList.add("expand");
  }

  render () {
    return (
      <span className="cell--labels" onClick={e => this.onClick(e)}>
        { this.getPillsFromValue().map((o, idx) => {
            return <span className="cell--pill" style={{ color: this.getTagColor(o.trim()), borderColor: this.getTagColor(o.trim()) + "40" }} tabIndex="2" onKeyDown={e => this.deletePill(e, idx)}>{ o.trim() }</span>;
          }) }
        <span className="pseudo-focus arrow" focusable="true" tabIndex="1" ref={node => this.pseudo_focus = node}></span>
        <Select
          onChange={tag => this.addPill(tag)}
          options={this.showRemainingOptions()}
          arrowPosition={this.props.align}
          align={this.props.align}
          customOptionBody={
            <div className="select-configure--add-tag-wrapper">
              <ContentEditable
                html={""}
                ref={node => this.add_tag_input = node ? node.getEl() : this.add_tag_input}
                onKeyDown={e => e.keyCode === 13 && this.preventEnterAndAddTag(e)}
                onChange={e => console.log("change")}
                className="select-configure--add-tag"
                data-placeholder={"Add Tag"}
              />
              <button onClick={e => this.addTag()}>+ Add</button>
            </div>
          }
        />
      </span>
    );
  }
}
