import React from 'react';

import Text from "./cells/Text.js";
import LongText from "./cells/LongText.js";
import PhoneNumber from "./cells/PhoneNumber.js";
import LatLong from "./cells/LatLong.js";
import Checkbox from "./cells/Checkbox.js";
import NumberCell from "./cells/Number.js";
import Computed from "./cells/Computed.js";
import Tags from "./cells/Tags.js";
import SelectCell from "./cells/Select.js";
import Rating from "./cells/Rating.js";
import Date from "./cells/Date.js";

import preview from "../../assets/preview.png";

import Icons from "../modules/Icons.js";
const { OpenIcon } = Icons;

export default class TableRows extends React.Component {
  constructor () {
    super();

  }

  render () {
    const ranges = this.props.columns.map((o, col_idx) => {
      if (o.type !== "Number") return;

      let values = this.props.spreadsheet.map(row => parseFloat(row[col_idx].value)).filter(o => !isNaN(o));
      let min = Math.min.apply(Math, values);
      let max = Math.max.apply(Math, values);

      return { min, max };
    });

    return (
      <>
      { this.props.spreadsheet.map((row, row_idx) => (
        <div className="row" key={row_idx}>
          <OpenIcon className="cell cell--action preview" onClick={() => this.props.setInformationSidebar(row, row_idx)} />
        { row.map((col, idx) => (
            <div
              className={"cell " + "cell-" + idx}
              onMouseDown={e => this.props.onCellMouseDown(e, idx)}
              onMouseMove={e => this.props.onCellMouseMove(e, idx)}
              onMouseUp={e => this.props.onCellMouseUp()}
              key={idx}
              data-type={this.props.columns[idx].type}
            >
            {(() => {
              const { type, config } = this.props.columns[idx];

              if (type === "Text") {
                return (
                  <Text
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Phone Number") {
                return (
                  <PhoneNumber
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    config={config || {}}
                  />
                )
              } else if (type === "Lat/Long") {
                return (
                  <LatLong
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Long Text") {
                return (
                  <LongText
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Checkbox") {
                return (
                  <Checkbox
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    value={col.value}
                    config={config || {}}
                  />
                )
              } else if (type === "Number") {
                return (
                  <NumberCell
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    rangeValues={ranges[idx]}
                    config={config || {}}
                  />
                )
              } else if (type === "Computed") {
                return (
                  <Computed
                    row={row.map(cell => cell.value)}
                    columns={this.props.columns}
                    config={config || {}}
                  />
                )
              } else if (type === "Tags") {
                return (
                  <Tags
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                    align={idx <= 1 ? "left" : "right"}
                    addTag={tags => this.props.saveColumnAttribute(idx, { tags })}
                  />
                )
              } else if (type === "Select") {
                return (
                  <SelectCell
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                    config={config || {}}
                    align={idx <= 1 ? "left" : "right"}
                    addSelectOption={selectOptions => this.props.saveColumnAttribute(idx, { selectOptions })}
                  />
                )
              } else if (type === "Rating") {
                return (
                  <Rating
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    value={col.value}
                  />
                )
              } else if (type === "Date") {
                return (
                  <Date
                    getCellValue={() => this.props.getCellValue(row_idx, idx)}
                    save={value => this.props.setCellValue(row_idx, idx, value)}
                    config={config || {}}
                  />
                )
              }
            })()}
              <div className="resize-me"></div>
            </div>
          )) }
          <div
            className={"cell cell--action"}
            onMouseDown={e => this.props.onCellMouseDown(e, this.props.spreadsheet[0].length)}
            onMouseMove={e => this.props.onCellMouseMove(e, this.props.spreadsheet[0].length)}
            onMouseUp={e => this.props.onCellMouseUp()}
          >
          </div>
          <div className="delete-row" onClick={() => this.props.deleteRow(row_idx)}>&times;</div>
        </div>
      )) }
        {!this.props.readOnly && <div className="add-row">
          <button onClick={() => this.props.addRow()}>+ Add Row</button>
        </div>}
      </>
    );
  }
}
