import React from 'react';
import checkmark from "../../assets/checkmark.png";

export default class LoadTable extends React.Component {
  constructor () {
    super();

    this.state = {

    }
  }

  componentDidMount () {

  }

  componentDidUpdate () {

  }

  render () {
    return (
      <div className={"table--loading " + (!this.props.show ? "hide" : "")} style={{ width: `100%`, height: `100%` }}>
        <div className="loading--text">Loading...</div>
      </div>
    )
  }
}
