import ReactDOM from 'react-dom';
import React from 'react';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import MainApp from './components/MainApp.js';
import Login from './components/Login.js';

import '@babel/polyfill';

(function(history){
    var pushState = history.pushState;
    history.pushState = function(state) {
        // ... whatever else you want to do
        // maybe call onhashchange e.handler
        let payload = pushState.apply(history, arguments);
        if (typeof history.onpushstate == "function") {
            history.onpushstate({state: state});
        }
        return payload;
    };
})(window.history);

class App extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/login/" exact component={Login} />
            <Route path="/*" exact component={MainApp} />
          </Switch>
        </Router>
      </>
    );
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
