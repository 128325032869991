import React from 'react';
import ContentEditable from '../../modules/third/react-contenteditable.js';

import computeStyleFromConfig from '../../modules/ComputeStyleFromConfig.js';
import Select from "../../modules/Select.js";

import Cell from "./Cell.js";

export default class Text extends Cell {
  constructor () {
    super();
    this.state = {
      show_dropdown: false,
    };
    this.add_tag_input = null;
    this.pseudo_focus = null;
    this.colors = ["#d17572", "#56acd0", "#df9b54", "#a769b6", "#55cb7a", "#d7c800"];
  }

  addPill (tag) {
    this.props.save(tag);
    this.pseudo_focus.blur();
  }

  deletePill (e, idx) {
    if (e.keyCode === 8) {
      this.props.save("");
    }
  }

  addSelectOption () {
    const tags = this.props.config.selectOptions || [];
    let val = this.add_tag_input.innerText.trim();

    if (val.length > 0 && tags.indexOf(val) === -1) {
      this.props.addSelectOption(tags.concat(val));
      this.add_tag_input.innerText = "";
    } else if (tags.indexOf(val) !== -1 && this.props.value !== val) {
      // the pill already exists, so they're probably just trying to type it.
      this.addPill(val);
      this.add_tag_input.innerText = "";
    }
  }

  getSelectOptionColor (tag) {
    const idx = (this.props.config.selectOptions || []).indexOf(tag);
    if (idx !== -1) {
      return this.colors[idx % this.colors.length];
    }
  }

  preventEnterAndAddSelect (e) {
    e.preventDefault();
    this.addSelectOption();
  }

  render () {
    return (
      <span className="cell--labels">
        { this.props.value.length > 0 &&
          <span
            className="cell--pill"
            tabIndex="2"
            style={{ color: this.getSelectOptionColor(this.props.value), borderColor: this.getSelectOptionColor(this.props.value) + "40" }}
            onKeyDown={e => this.deletePill(e)}>
            { this.props.value }
          </span> }
        { this.props.value.length === 0 && <span className="pseudo-focus arrow" focusable="true" tabIndex="1" ref={node => this.pseudo_focus = node}></span> }
        <Select
          onChange={tag => this.addPill(tag)}
          options={this.props.config.selectOptions || []}
          arrowPosition={this.props.align}
          align={this.props.align}
          defaultValue={this.props.value}
          customOptionBody={
            <div className="select-configure--add-tag-wrapper">
              <ContentEditable
                html={""}
                ref={node => this.add_tag_input = node ? node.getEl() : this.add_tag_input}
                onKeyDown={e => e.keyCode === 13 && this.preventEnterAndAddSelect(e)}
                className="select-configure--add-tag"
                data-placeholder={"Add Tag"}
              />
              <button onClick={e => this.addSelectOption()}>+ Add</button>
            </div>
          }
        />
      </span>
    );
  }
}
